<template>
 <div>
  
    <div class="bg_new">
      <div class="form_info_new ">
          <div class="form_info_view_new">
          <img class="logo_pic_new" src="../../assets/logo.jpg" />
            <h1 class="login_title_new" > 需求响应系统</h1>
            <div class="tabcontent_new flex_center">
                <div style="color: #8590a6; "><span>中国 +86</span></div>
                 <div class="svg_logo_new flex_center">
                  <svg  style="color:#8590a6;" fill="currentColor" viewBox="0 0 24 24">
                   <path d="M12 16.183l2.716-2.966a.757.757 0 0 1 1.064.001.738.738 0 0 1 0 1.052l-3.247 3.512a.758.758 0 0 1-1.064 0L8.22 14.27a.738.738 0 0 1 0-1.052.758.758 0 0 1 1.063 0L12 16.183zm0-9.365L9.284 9.782a.758.758 0 0 1-1.064 0 .738.738 0 0 1 0-1.052l3.248-3.512a.758.758 0 0 1 1.065 0L15.78 8.73a.738.738 0 0 1 0 1.052.757.757 0 0 1-1.063.001L12 6.818z" fill-rule="evenodd"></path>
                   </svg>
                      </div>
                     <input class="tabcontentinput" placeholder="请输入手机号" v-model="tel" v-on:input ="inputFunc" type="tel">
                     
                     <img class="phone_logo" src="../../assets/phone.png" >
            </div>  

                <div class="tabcontent_new flex_center">
                     <input  class="tabcontentinput_code_new" placeholder="请输入4位验证码"  v-model="code"  @keyup.enter="toLogin" v-on:input ="inputFunc1" >
                          <template  v-if="clickcode==false" >
                          <el-button type="primary" class="code_new"  v-if="showcode==true"   @click="tovcode" >
                              发送验证码
                          </el-button>

                              <el-button type="primary"  class="code_new"  disabled v-else style="background: #1890ff87; border-color: #1890ff87;color: white">
                                  发送验证码
                              </el-button>
                           </template>
                     

                           <template  v-else >

                          {{count}}s重新获取验证码
                              </template>
                </div>
                  <div v-if="error" class="error_msg">
                      {{error}}
                  </div>
                  <div class="button_view flex_center" :class="error?'button_view_msg':''">
                      <el-button  class="button_round_new" v-if="buttonshow==true" type="primary" round block @click="toLogin"  @keyup.enter.native="enterLogin()"  >
                          登录
                      </el-button>
            
                      <el-button type="primary"  v-if="buttonshow==false"  class="button_round_new" block style=" background: #1890ff87; border-color: #1890ff87;color: white"  disabled >
                          登录
                      </el-button>
                 </div>
               </div>  
      </div>
    </div>

    </div>
</template>

<script>
    export default {
        name: "login",
        data(){
            return{
                count:0,
                clickcode:false,
                tel:"",
                showcode:false,
                code:"",
                buttonshow:false,
                error:"",
                timer:null
            }
        },
        mounted() {


           if(localStorage.getItem('token')){
          this.$router.push({path:"/demandResponse",replace: true});

          }
          this.enterLogin()
        },
        methods:{
               inputFunc(value){

                this.telchange(this.tel)
            },
            toLogin() {
                let that=this
                let datalist = {
                    phone:this.tel,
                    code:this.code
                }
                that.$axiosPost('/api/User/LoginFromForm',datalist,{},true).then(response=>{

            if (response.Tag == 1) {

          localStorage.setItem("tokenStartTime", new Date().getTime());
          localStorage.setItem("token", response.Data);
 
        
          setTimeout(()=>{
           this.$router.replace({path:'/demandResponse',replace: true,query: {isLogin:true}});
          },500)
        } else {
          this.error = response.Message;

        }
                })
             
  
 

            },
            inputFunc1(value){
                if(this.tel.length>0&&this.code.length==4){
                    this.buttonshow=true
                }
                else{
                    this.buttonshow=false
                }

            },
            telchange(e) {
                var myreg =/^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
                if (!myreg.test(this.tel)) {
                    this.showcode = false
                } else {
                    this.showcode = true
                }

            },
            tovcode() {
                if (this.showcode == true) {
                    this.clickcode = true
                    const TIME_COUNT = 60;
                    this.requestvcode()
                    if (!this.timer) {
                        this.count = TIME_COUNT;

                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.clickcode = false;
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000)
                    }
                }


            },
            requestvcode(){
                if(this.clickcode==true){
                    let datalist = {
                      phone:this.tel
                    }

                  this.$axiosPost('/api/Message/GetCheckedCodeFromForm',datalist,{},true).then(res=>{

                  })
            
           
                }
            },
            enterLogin(){
      document.onkeydown =(e) => { 
        e = window.event || e;
        if((e.code=='Enter'||e.code=='enter')&&this.tel&&this.code){
          //调用登录事件方法
          this.toLogin();
        }
      }
    }



        }
    }
</script>

<style lang="scss" scoped>
   body{
  padding: 0;
  margin: 0;
  border:0;
  position: relative;
}
    .bg{
        background-image:url('../../assets/img/background.jpg');

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-width: 1000px;
        z-index: 0;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .form-info {
        width: 480px;
        height: 680px;
        border-radius: 10px;
        background-color: white;
        position: absolute;
        margin: 0 auto;
        bottom: 50%;
        margin-bottom: -340px;
        right: 140px;
        padding: 20px 10px;
        z-index: 99;
    }
    .logo_pic{
   width: 20%;
   margin-top: 20px;
    }
    .login_title{
   color: rgb(255, 192, 0); font-size: 60px;
   margin-top: 20px;

    }
    .tabcontent {
        margin-top: 24px;
        border-bottom: 1px solid #ebebeb;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      padding: 20px 40px;
        -ms-flex-align: center;
        align-items: center;
    }
    .tabcontentinput{
        display: inline-block;
        outline: none;
        flex: 1 1;
        padding: 0;
        overflow: hidden;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        background: transparent;
        border: none;
        resize: none;
        z-index: 100;
        font-size: 14px;
    }
        .tabcontentinput_code{
        display: inline-block;
        outline: none;
        flex: 1 1;
        padding: 0;
        overflow: hidden;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        background: transparent;
        border: none;
        resize: none;
        z-index: 100;
        font-size: 14px;
        margin-right: 100px;
    }
    .button_round{
        margin-top: 50px;
        border-radius: 50px;
        font-size: 16px;
        width:100%;
        height: 50px;
    }
    .phone_logo{
width: 70px;margin-right:30px;text-align: right;
    }
    .code{
        font-size: 13px;
        padding: 8px 13px;
        
    }
    .error_style{
    color: red; margin-top: 5px;text-align: center;
    }
    //新的
    .bg_new{
        background:url('../../assets/img/background.jpg') no-repeat;
        background-size: cover;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 0;

    }
    .form_info_new{
        width: 480px;
        height: 680px;
        border-radius: 8px;
        background:white;
        position: absolute;
        right: 140px;
        bottom: 50%;
        margin-bottom: -340px;
        z-index: 99;
           }   
     .form_info_view_new{
        //  width: 100%;
         height: 100%;
          display: flex;
        //   justify-content: center;
        //   align-items: center;
          flex-direction: column;
         padding: 50px 10px;
  
        

    .logo_pic_new{
    width: 20%;
   margin-top: 20px;
    }
    .login_title_new{
    color: rgb(255, 192, 0); 
    font-size: 60px;
    margin-top: 20px;
    text-align: center;
    }
     .tabcontent_new {
        margin-top: 24px;
        border-bottom: 1px solid #ebebeb;
        padding: 20px 40px;
        font-size: 14px;
    }
        .tabcontentinput_new{
        display: inline-block;
        outline: none;
        flex: 1 1;
        padding: 0;
        overflow: hidden;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        background: transparent;
        border: none;
        resize: none;
        z-index: 100;
        font-size: 14px;
    }
        .tabcontentinput_code_new{
        display: inline-block;
        outline: none;
        flex: 1 1;
        padding: 0;
        overflow: hidden;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        background: transparent;
        border: none;
        resize: none;
        z-index: 100;
        font-size: 14px;
        margin-right: 100px;
    }
        .phone_logo_new{
        width: 70px;
        margin-right:30px;
        text-align: right;
    }
    .code_new{
        font-size: 13px;
        padding: 8px 13px;
        
    }
    .button_view{
     width:100%;
     height: 50px;
     margin-top: 80px;

    }
    .button_view_msg{
        margin-top: 50px;
    }
    .button_round_new{
       width: 100%;
       height: 100%;
        border-radius: 50px;
        font-size: 16px;

    }
    .svg_logo_new{
        width: 30px;
        height: 30px;
        svg{
            width: 100%;
            height: 100%;
        }
    }
        }
 .error_msg{
     color: red;
     font-size: 14px;
     margin-top: 20px;
     text-indent: 2em;
 }
</style>